import {Component, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {ReleaseNote} from '../../classes/release-note';
import {HelpService} from '../../services/help.service';
import {ApplicationConfig} from '../../../../classes/application-config';

@Component({
    selector: 'eaglei-release-notes',
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.scss'],
    standalone: false,
})
export class ReleaseNotesComponent {
    // DOM Elements
    @ViewChild(MatDrawer) drawer: MatDrawer;

    // Release Notes Properties
    public releaseNotes: ReleaseNote[] = [];
    public activeNote: ReleaseNote;

    // Drawer Properties
    get isDrawerOpened(): boolean {
        return this.drawer ? this.drawer.opened : false;
    }

    constructor(private helpService: HelpService) {
        this.helpService.getReleaseNotes().subscribe((notes) => {
            this.releaseNotes = notes;
            this.activeNote = notes[0];
        });
    }

    /**
     * Toggles Drawer to open or close
     */
    public toggleDrawer(): void {
        this.drawer.opened ? this.drawer.close() : this.drawer.open();
    }

    public useMobileLayout(checkPhone?: boolean): boolean {
        if (checkPhone) {
            return ApplicationConfig.onPhone();
        }

        return ApplicationConfig.useMobileLayout();
    }

    public setActiveNote(note: ReleaseNote): void {
        this.activeNote = note;
        if (this.useMobileLayout(true)) {
            // noinspection JSIgnoredPromiseFromCall
            this.drawer.close();
        }
    }
}
