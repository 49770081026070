<mat-drawer-container class="new-design" [hasBackdrop]="false">
    <mat-drawer mode="over" position="end" [class.wide]="useMobileLayout(true)">
        <div class="release-info">
            <div class="drawer">
                <h3 class="drawer-header">Previous Release</h3>
            </div>

            <div
                *ngFor="let note of releaseNotes"
                class="version-info"
                [class.selected-note]="note === activeNote"
                (click)="setActiveNote(note)">
                <div>{{ note.released | momentDate : 'L' }}</div>
                <div>
                    {{ note.majorVersion }}
                    <fa-icon icon="circle" class="small-icon"></fa-icon>
                    {{ note.minorVersion }}{{ note.buildIdentifier != '' ? '.' + note.buildIdentifier : '' }}
                </div>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="expand-container" [class.opened-drawer]="isDrawerOpened" (click)="toggleDrawer()">
            <fa-icon icon="history"></fa-icon>
        </div>

        <div class="note-info">
            <div *ngIf="activeNote">
                <br />
                <div>
                    Released On <strong>{{ activeNote.released | momentDate : 'l' }}</strong>
                </div>
                <div>
                    Major Version <strong>{{ activeNote.majorVersion }}</strong>
                </div>
                <div>
                    Minor Version
                    <strong
                        >{{ activeNote.minorVersion }}{{ activeNote.buildIdentifier != '' ? '.' + activeNote.buildIdentifier : '' }}</strong
                    >
                </div>
                <br />
                <div>
                    Notes:
                    <div [innerHTML]="activeNote.notes | sanitize" class="release-details"></div>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
